<template>
  <!-- <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div> -->
  <div ref="homeRef">
    <!-- 页1 -->
    <div class="firstPage">
      <!-- 视频播放-->
      <div class="videoBox">
        <!-- <video
          class="video1"
          ref="video1Ref"
          poster="@/assets/videoLoading2.png"
          :src="videoSrc"
          muted="muted"
          @ended="playNextVideo"
        ></video> -->

        <!-- <video
        @ended="playNextVideo"
        @loadeddata="handleLoadedData"
        @canplaythrough="handleCanPlayThrough"
      ></video> -->
        <!-- src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/a3a525c0ed0be3cd3946c7ecb95a2175.mp4" -->
        <!-- src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/a3a525c0ed0be3cd3946c7ecb95a2175.mp4" -->
        <video
          class="video3"
          ref="video3Ref"
          src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/Model-20210329-10-brain-neuron488-LEL-dylight594-IS4h-tMCAO-thy1%20TG-5month-TMH-488.mp4"
          muted="muted"
          @ended="playNextVideo(3)"
        ></video>
        <!-- src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/f5db7017de14fba1549795ec5b039588.mp4" -->
        <!-- src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/9cbcb79f826594c329acfc3361b6a007.mp4" -->
        <video
          class="video2"
          ref="video2Ref"
          src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/2.mp4"
          muted="muted"
          @ended="playNextVideo(2)"
        ></video>
        <!-- src="@/assets/brain1.mp4" -->
        <!-- src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/9cbcb79f826594c329acfc3361b6a007.mp4" -->
        <!-- poster="@/assets/videoLoading1.png" -->
        <video
          class="video1"
          ref="video1Ref"
          poster="@/assets/videoLoading2.png"
          src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/f5db7017de14fba1549795ec5b039588.mp4"
          muted="muted"
          @ended="playNextVideo(1)"
        ></video>
      </div>
      <!-- 无缝轮播 -->
      <!-- <div class="outer" ref="outerRef">
        <div class="main">
          <div class="item">
            <span class="th">THOUSAND</span>
            <span class="iconfont">&#xe600;</span>
            <span class="di">DIMENSIONS</span>
            <span class="iconfont">&#xe600;</span>
          </div>
          <div class="item">
            <span class="th">THOUSAND</span>
            <span class="iconfont">&#xe600;</span>
            <span class="di">DIMENSIONS</span>
            <span class="iconfont">&#xe600;</span>
          </div>
          <div class="item">
            <span class="th">THOUSAND</span>
            <span class="iconfont">&#xe600;</span>
            <span class="di">DIMENSIONS</span>
            <span class="iconfont">&#xe600;</span>
          </div>
          <div class="item">
            <span class="th">THOUSAND</span>
            <span class="iconfont">&#xe600;</span>
            <span class="di">DIMENSIONS</span>
            <span class="iconfont">&#xe600;</span>
          </div>
          <div class="item">
            <span class="th">THOUSAND</span>
            <span class="iconfont">&#xe600;</span>
            <span class="di">DIMENSIONS</span>
            <span class="iconfont">&#xe600;</span>
          </div>
        </div>
      </div> -->
      <h1 class="td_name" ref="tdNameRef">{{ $t("public.companyName") }}</h1>
      <!-- 搜索框 -->
      <div class="searchBox" ref="searchBoxRef">
        <div
          class="searchInputBox"
          :style="{
            'outline-color':
              videoIndex == 0
                ? '#ff274c'
                : videoIndex == 1
                ? '#e92ea3'
                : '#add800',
          }"
        >
          <input
            v-model.trim="key"
            ref="searchRef"
            type="text"
            class="searchInput"
            @focus="handleFocus"
            @input="handleInput"
            @keyup.enter="handleClick"
            @blur="handleBlur"
          />
        </div>
        <img
          ref="magnifierRef"
          :src="
            videoIndex == 0
              ? require('@/assets/magnifier_red.png')
              : videoIndex == 1
              ? require('@/assets/magnifier_purple.png')
              : require('@/assets/magnifier_lemon.png')
          "
          alt=""
          class="magnifier"
          @click="handleClick"
        />
      </div>
    </div>
    <!-- 页2 -->
    <div class="secondPage" ref="secondPageRef">
      <div class="secondPage1">
        <div class="line"></div>
        <div class="line2">
          <span class="left">
            <span :class="{ iconfont: true, iconfont1: true }">&#xe602;</span>
            <span :class="{ iconfont: true, iconfont2: true }">&#xe604;</span>
          </span>
          <span class="right">{{
            $i18n.locale == "ch"
              ? "放大，我们的成果"
              : "Zoom in our achievements"
          }}</span>
        </div>
      </div>
      <!-- 鼠图片手动滚动 -->

      <div class="secondPage2" id="secondPageTwo">
        <!-- 绝对定位 -->
        <ul class="titleBox" @click="titleMouseOverHanlder" ref="titleBoxRef">
          <!-- , titleActive: handleTitleActive(index) -->
          <li
            v-for="(item, index) in titleArr"
            :key="index"
            :data-id="index"
            :class="{
              commonLi: true,
              commonLiEn: $i18n.locale == 'en',
              titleActive: handleTitleActive(index),
              showLiAnimation1: index == 0 && isInView,
              showLiAnimation2: index == 1 && isInView,
              showLiAnimation3: index == 2 && isInView,
              showLiAnimation4: index == 3 && isInView,
            }"
          >
            {{ $i18n.locale == "ch" ? item.title : item.titleEn }}
          </li>
        </ul>
        <div>
          <swiper
            :modules="modules"
            :autoplay="{ delay: 2000, disableOnInteraction: false }"
            :loop="true"
            :grabCursor="true"
            :slides-per-view="1.6"
            :space-between="50"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            @mouseenter="pauseAutoplay"
            @mouseleave="startAutoplay"
          >
            <swiper-slide
              class="slide"
              v-for="(item, index) in titleArr"
              :key="index"
            >
              <div
                class="box"
                @mouseenter="imgMouseEnter($event, index)"
                @mousemove="imgMouseMove($event, index)"
                @mouseleave="imgMouseLeave($event, index)"
              >
                <img
                  :src="require(`@/assets/mirrow${index + 1}.jpg`)"
                  :id="'originImg' + index"
                  alt=""
                  class="itemImg"
                />
                <div class="mirrow" :id="'mirrow' + index">
                  <!-- 大图 -->
                  <img
                    :src="require(`@/assets/mirrow${index + 1}.jpg`)"
                    alt=""
                    class="bigImg"
                    :id="'imgBig' + index"
                  />
                </div>
                <div class="itemTitle">
                  {{ $i18n.locale == "ch" ? item.title : item.titleEn }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <!-- <div class="secondPage2">
        绝对定位
        <ul class="titleBox" @click="titleMouseOverHanlder">
          <li
            :class="{ commonLi: true, titleActive: index == currentTitleIndex }"
            v-for="(item, index) in titleArr"
            :key="index"
            :data-id="index"
            :ref="setLiRef"
          >
            {{ $i18n.locale == "ch" ? item.title : item.titleEn }}
          </li>
        </ul>
         @mousedown="main2RefMouseDown" @mousedown="main2RefMouseDown"
        <div class="mainOuter" ref="mainOuterRef">
          <div class="outerInner" ref="mainOuterInnerRef">
            
          </div>
          @mousedown="main2RefMouseDown"
            @mousemove="main2RefMouseMove"
            @mouseup="main2RefMouseUp"
          <div
            class="main"
            ref="main2Ref"
            @mouseenter="main2RefMouseEnter"
            @mouseleave="main2RefMouseLeave"
          >
            <div
              class="item"
              v-for="(item, index) in titleArr"
              :key="index"
              @mouseenter="imgMouseEnter($event, index)"
              @mousemove="imgMouseMove($event, index)"
              @mouseleave="imgMouseLeave($event, index)"
            >
              小图  mirrow1.jpg
              <img
                :src="require(`@/assets/mirrow${index + 1}.jpg`)"
                alt=""
              />
              放大镜
              <div class="mirrow" ref="mirrowRef">
                大图
                <img
                  :src="require(`@/assets/mirrow${index + 1}.jpg`)"
                  alt=""
                  class="bigImg"
                />
              </div>
              title
              <div class="itemTitle">
                {{ $i18n.locale == "ch" ? item.title : item.titleEn }}
              </div>
            </div>
          </div>
        </div>

        <div class="itemTitleList" ref="itemTitleListRef">
          <div class="itemTitle" v-for="(item, index) in titleArr" :key="index">
            {{ $i18n.locale == "ch" ? item.title : item.titleEn }}
          </div>
        </div>
      </div> -->
    </div>
    <!-- 页3 -->
    <div class="thirdPage" ref="thirdPageRef">
      <div :class="{ secondPage1: true, thirdPage1: true }">
        <div class="line"></div>
        <div class="line2">
          <span class="left">
            <span :class="{ iconfont: true, iconfont1: true }">&#xe602;</span>
            <span :class="{ iconfont: true, iconfont2: true }">&#xe605;</span>
          </span>
          <span class="right" ref="aboutLookDom">{{
            $i18n.locale == "ch" ? "来看，关于我们" : "Come and see, about us"
          }}</span>
        </div>
      </div>
      <div class="main" ref="thirdPageMainDom">
        <div class="mainInner" ref="thirdPageMainInnerDom"></div>
        <!-- src="@/assets/teacherWang.mp4" -->
        <video
          src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/05a5466ef2257c2169fa48749e50287a.mp4"
          controls
          class="videoLeft"
          ref="videoLeftDom"
        ></video>
        <!-- src="@/assets/TDDATA.mp4" -->
        <video
          src="https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/6f05c24869256ba713571015a7c4ef60.mp4"
          controls
          class="videoRight"
          ref="videoRightDom"
        ></video>
      </div>
    </div>

    <!-- 页4 -->
    <div class="fourthPage" ref="fourthPageRef">
      <div :class="{ secondPage1: true }">
        <div class="line"></div>
        <div class="line2">
          <span class="left">
            <span :class="{ iconfont: true, iconfont1: true }">&#xe602;</span>
            <span :class="{ iconfont: true, iconfont2: true }">&#xe606;</span>
          </span>
          <span class="right">{{
            $i18n.locale == "ch" ? "关注，最新文章" : "Follow latest articles"
          }}</span>
        </div>
      </div>
      <div class="main1">
        <div
          class="item"
          v-for="(item, index) in articleList1"
          :key="index"
          @click="handleToDetail(item.articleChain)"
        >
          <div
            class="itemTop"
            @mouseenter="itemTopimgAbsoluteMouseEnter"
            @mouseleave="itemTopimgAbsoluteMouseLeave"
          >
            <img
              :src="'http://www.qianchengjx.com' + item.articlePicture"
              alt=""
              class="itemTopimg"
            />
            <!-- mouseOverPic -->
            <img
              :src="'http://www.qianchengjx.com' + item.mouseOverPic"
              alt=""
              class="itemTopimgAbsolute"
            />
          </div>
          <div class="itemBottom">
            <span class="itemBottom1">{{
              $i18n.locale == "ch"
                ? "公众号文章"
                : "WeChat public account article"
            }}</span>
            <h1 class="itemBottom2">
              {{ item.articleTitle }}
            </h1>
            <span>
              <span class="itemBottom3">{{ item.articleContent }}</span>
              <img
                src="@/assets/circleIn.png"
                alt=""
                class="itemBottom2Img"
                @mouseenter="itemBottom2ImgMouseEnter"
                @mouseleave="itemBottom2ImgMouseLeave"
                ref="itemBottom2ImgDom"
              />
            </span>
            <span :class="{ itemBottom1: true, itemBottom4: true }">{{
              item.createTime
            }}</span>
          </div>
        </div>
      </div>
      <div class="main2">
        <div class="main2Top">
          <span class="main2Top1">{{
            $i18n.locale == "ch" ? "日期" : "Date"
          }}</span>
          <span class="main2Top2">{{
            $i18n.locale == "ch" ? "标题" : "Title"
          }}</span>
          <span class="main2Top3">{{
            $i18n.locale == "ch" ? "文章类型" : "Article type"
          }}</span>
        </div>
        <div
          class="main2Bottom"
          ref="main2BottomRef"
          @mouseenter="item2InnerMouseEnter"
          @mouseleave="item2InnerMouseLeave"
        >
          <img ref="squareImgRef" class="squareImg" src="" alt="" />
          <div
            v-for="(item, index) in articleList2"
            :key="index"
            class="main2BottomItem"
            @mousemove="item2InnerMouseMove(index, $event)"
            @click="handleToDetail(item.articleChain)"
          >
            <span class="item1">{{ item.createTime }}</span>
            <span class="item2"
              ><span class="item2Inner">{{ item.articleTitle }}</span>
              <!-- <img
              v-if="!item.squarePic"
              :src="'http://www.qianchengjx.com' + item.articlePicture"
              alt=""
              class="item2Img" /><img
              v-if="item.squarePic"
              :src="'http://www.qianchengjx.com' + item.squarePic"
              alt=""
              class="item2Img"
          /> -->
            </span>
            <span class="item3"></span>
            <span class="item4">{{
              $i18n.locale == "ch"
                ? "公众号文章"
                : "WeChat public account article"
            }}</span>
          </div>
        </div>
        <div class="main2Bottom2">
          <span class="main2Bottom21" @click="toHotArticles">{{
            $i18n.locale == "ch" ? "去看更多" : "More"
          }}</span>
          <img
            @mouseenter="main2Bottom22Enter"
            @mouseleave="main2Bottom22Leave"
            src="@/assets/circleIn.png"
            alt=""
            class="main2Bottom22"
            @click="toHotArticles"
            ref="checkMoreRef"
          />
        </div>
      </div>
    </div>

    <!-- 页5 -->
    <div class="fifthPage" ref="fifthPageRef">
      <div :class="{ secondPage1: true }">
        <div class="line"></div>
        <div class="line2">
          <span class="left">
            <span :class="{ iconfont: true, iconfont1: true }">&#xe602;</span>
            <span :class="{ iconfont: true, iconfont2: true }">&#xe609;</span>
          </span>
          <span class="right">{{
            $i18n.locale == "ch"
              ? "定制，专属服务"
              : "Customize exclusive services"
          }}</span>
        </div>
      </div>
      <div class="main1">
        <!-- 疾病类型 -->
        <div v-for="(item, index) in serviceArr" class="itemBox" :key="index">
          <div class="itemTop">
            <span class="itemTop1">
              <input
                type="checkbox"
                name=""
                id=""
                class="itemTop11"
                v-model="item.checked"
                @change="itemCheckedChange(item)"
              />
            </span>
            <span class="itemTop2">{{
              $i18n.locale == "ch" ? item.label : item.labelEn
            }}</span>
          </div>
          <div class="itemBottom">
            <div
              v-for="(m, x) in item.children"
              :key="x"
              class="itemBottomItem"
            >
              <span class="itemBottomItemLeft">
                <input
                  v-model="m.checked"
                  type="checkbox"
                  name=""
                  id=""
                  class="itemBottomItemLeftInput"
                  @change="mCheckedChange(item, m)"
                />
              </span>
              <span class="itemBottomItemRight">{{
                $i18n.locale == "ch" ? m.label : m.labelEn
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部3个盒子 -->
      <div class="main2">
        <!-- 盒1 -->
        <!-- {{ $i18n.locale }} -->
        <div :class="{ main21: true, main20: true }">
          <template v-if="$i18n.locale == 'ch'">
            <h2
              v-for="(item, index) in notice1Arr"
              :key="index"
              class="main211"
            >
              {{ item.label }}
            </h2>
          </template>

          <template v-if="$i18n.locale == 'en'">
            <h2 class="main211">
              {{ notice1Arr[0].labelEnEn }}
            </h2>
          </template>

          <div :class="{ main212: true, main2121: true }">
            {{ $i18n.locale == "ch" ? "技术咨询" : "Technical consultation" }}
          </div>
          <div :class="{ main212: true, main2122: true }">
            {{
              $i18n.locale == "ch"
                ? "邮箱：TDCS@qianchengjx.com"
                : "Email：TDCS@qianchengjx.com"
            }}
          </div>
          <div :class="{ main212: true, main2123: true }">
            {{
              $i18n.locale == "ch" ? "电话：010-61251566" : "Tel：010-61251566"
            }}
          </div>
        </div>
        <!-- 盒2 -->
        <div :class="{ main22: true, main20: true }">
          <h2 class="main22H2">
            {{ $i18n.locale == "ch" ? "定制实验" : "Customize service" }}
          </h2>
          <div class="main22H4">
            {{
              $i18n.locale == "ch"
                ? "样品检测，样品制备，高清超微病理图像采集，二维及三维大数据批量定量处理，实验分析报告等。"
                : "Sample testing, sample preparation, high-resolution ultrastructural pathological image capture, 2D and 3D big data batch processing, experimental analysis report, etc."
            }}
          </div>
          <div class="main22Purpose">
            <span>{{ $i18n.locale == "ch" ? "订单名称" : "Order name" }}</span>
            <textarea
              name=""
              id=""
              :placeholder="$i18n.locale == 'ch' ? '必填项' : 'must'"
              v-model="formSubmit.orderName"
            ></textarea>
          </div>
          <div class="main22Purpose">
            <span>{{ $i18n.locale == "ch" ? "实验目的" : "Purpose" }}</span>
            <textarea
              name=""
              id=""
              v-model="formSubmit.experimentPurpose"
            ></textarea>
          </div>
          <div class="main22Remark">
            <span>{{ $i18n.locale == "ch" ? "委托说明" : "Description" }}</span>
            <textarea
              name=""
              id=""
              v-model="formSubmit.entrustInstructions"
            ></textarea>
          </div>
        </div>
        <!-- 盒3 -->
        <div :class="{ main23: true, main20: true }">
          <h2 class="main22H2">
            {{
              $i18n.locale == "ch"
                ? "开始您的定制服务吧！"
                : "Start your customized service!"
            }}
          </h2>
          <div class="main23Item">
            <span>{{ $i18n.locale == "ch" ? "姓名" : "Name" }}</span>
            <textarea
              name=""
              id=""
              :placeholder="$i18n.locale == 'ch' ? '必填项' : 'must'"
              v-model="formSubmit.userName"
            ></textarea>
          </div>
          <div class="main23Item">
            <span>{{ $i18n.locale == "ch" ? "手机" : "Phone" }}</span>
            <textarea
              name=""
              id=""
              :placeholder="$i18n.locale == 'ch' ? '必填项' : 'must'"
              v-model="formSubmit.phoneNumber"
            ></textarea>
          </div>
          <div class="main23Item">
            <span>{{ $i18n.locale == "ch" ? "邮箱" : "Email" }}</span>
            <textarea
              name=""
              id=""
              :placeholder="$i18n.locale == 'ch' ? '必填项' : 'must'"
              v-model="formSubmit.email"
            ></textarea>
          </div>
          <div class="main23Item">
            <span>{{ $i18n.locale == "ch" ? "单位" : "Company" }}</span>
            <textarea
              name=""
              id=""
              :placeholder="$i18n.locale == 'ch' ? '必填项' : 'must'"
              v-model="formSubmit.company"
            ></textarea>
          </div>
          <div class="main23Submit">
            <span
              class="submit1"
              @click="handleSubmitOrder"
              ref="submit1Ref"
              v-if="!loading"
              >{{ $i18n.locale == "ch" ? "提 交" : "Submit" }}</span
            >
            <!-- <span class="submit1" v-if="loading">{{
              $i18n.locale == "ch" ? "提交中" : "Submitting"
            }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import i18n from "@/language/index";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
// import brain1 from "@/assets/brain1.mp4";
// import brain2 from "@/assets/brain2.mp4";
// import brain3 from "@/assets/brain3.mp4";
// import imgUrl1 from "@/assets/videoLoading1.png";
// import imgUrl2 from "@/assets/videoLoading2.png";
// import imgUrl3 from "@/assets/videoLoading3.png";
import circleIn from "@/assets/circleIn.png";
import circleInIn from "@/assets/circleInIn.png";
import submitCircleOut from "@/assets/submitCircleOut.png";
import submitCircleIn from "@/assets/submitCircleIn.png";
import { baseUrl, scrollHome } from "@/utils/base";
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { getArticles, getSearchArticle, submitOrder } from "@/api/http";
import { Swiper, SwiperSlide } from "swiper/vue";
import { FreeMode, Navigation, Thumbs, Autoplay, A11y } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
export default {
  // name: 'Home',
  // components: {
  //   HelloWorld
  // }
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const router = useRouter();
    const homeRef = ref(null);
    const tdNameRef = ref(null);
    const searchBoxRef = ref(null);
    const video1Ref = ref(null);
    const video2Ref = ref(null);
    const video3Ref = ref(null);
    const videoPlayer = ref(null);
    const videoIndex = ref(0);
    const outerRef = ref(null);
    const searchRef = ref(null);
    const mainOuterRef = ref(null);
    const mainOuterInnerRef = ref(null);
    const itemTitleListRef = ref(null);
    const titleBoxRef = ref(null);
    const downX = ref(0);
    const marginLeft = ref(0);
    const main2Ref = ref(null);
    const maxLeftLeft = ref(0);
    const maxLeftRight = ref(0);
    const magnifierRef = ref(null);
    const main2OffsetX = ref(0);
    const mirrowRef = ref(null);
    const circleWidthHalf = ref(0);
    const currentTitleIndex = ref(0);
    const fourthPageRef = ref(null);
    const fifthPageRef = ref(null);
    const submit1Ref = ref(null);
    const loading = ref(false);
    const main2BottomRef = ref(null);
    const squareImgRef = ref(null);
    const isInView = ref(false);
    const keyScopeHome = [
      "",
      "小鼠全脑血管APP/小鼠海马DG区APP/PS1小鼠全脑神经元区小鼠星型胶质细胞",
      "",
      "公众号文章",
      "定制服务下单药效学评价急性脑缺血阿尔兹海默症糖尿病脑病慢性脑缺血帕金森氏病急慢性抑郁症高血压脑病渐冻症细胞水平癫痫",
    ];
    const keyScopeAbout = [
      "千乘镜像（北京）科技有限公司是一个集全脑及各组织病理成像病理模型超微结构数据库应用多种现代可视成像技术进行药物药效学评价及医学影像AI辅助诊断及分析软件为一体的综合型生物科技公司",
      "首席科学家王晓良教授公司国家药效学平台负责人中国医学科学院药物研究所原所长具有丰富的药效学评价经验已累计主持和参与评价新药100多种其中申报国家一类新药十余种是我国新药研发和药理学领域的领军人物",
      "本公司采用先进的成像设备细胞染色技术实现对各类来源的样品进行高分辨率成像并提供辅助分析服务同时本公司根据客户需求为客户定制实验方案，提供全套的新药药效学评价服务和完整的实验报告实现从局部到整体超微结构及意识行为的结合解析神经科学的奥秘发现新型药物靶点为阐释疾病机理药物对疾病的作用提供最新思路和视角",
    ];
    const keyScopeTech = [
      "疾病模型药物评价急性脑缺血模型慢性脑缺血模型高血压及高血压脑病模型阿尔茨海默症模型帕金森氏病模型肌萎缩侧索硬化症渐冻症模型糖尿病脑病模型急慢性抑郁症模型血管性痴呆模型肾功能衰竭模型心脏缺血及心率失常模型肿瘤模型",
      "技术服务评价方法指标常规评价方法造模给药行为学评价梗死体积血压心率生化检测评价ELISAWESTERNPCR免疫荧光标记常规病理",
      "显微断层光学成像技术fMOST组织透明化显微光片成像技术近红外二区活体成像技术核磁成像技术质普成像技术毛细血管",
      "细胞水平药物筛选评价神经元或转基因细胞氧糖剥夺损伤模型LPS炎症损伤模型星形胶质细胞损伤模型少突胶质细胞损伤模型流式细胞技术检测细胞凋亡细胞周期以及多种蛋白表达",
      "离子通道药物筛选评价钠离子通道药物筛选及评价Na1.5Nav1.7Nav1.8钙离子通道药物筛选及评价Cav1.2Cav2.2Cav3.2钾离子通道药物筛选及评价Kv1.3Kv4.2HERGKv1.5Kv4.3TREK-1Kv2.1Kv7.1Kir2.1Kv3.1Kir4.1GABA受体药物筛选评价AMPA",
      "3D生物打印培养药物筛选评价3D打印神经元模型3D打印脑血管模型3D疾病建模",
    ];
    const keyScopeProduct = [
      "",
      "千乘油性透明化试剂盒千乘全血管标记物千乘水性透明化试剂盒千乘活体透明化试剂盒千乘镜像器官组织存储EP管千乘小样送样盒阿尔茨海默病脑超微结构病理图谱荧光病理模式动物",
      "数字产品清单",
      "TD DATA TD Combiner TD ACI TD ANA TD GAS TD Samples 自主研发软件",
    ];

    //

    const titleArr = ref([
      {
        title: "小鼠全脑血管",
        titleEn: "Mouse blood vessel of brain",
      },
      {
        title: "APP/小鼠海马DG区",
        titleEn: "APP/DG region of mouse hippocampus",
      },
      {
        title: "APP/PS1小鼠全脑神经元区",
        titleEn: "APP/PS1 neuronal region of mouse brain",
      },
      { title: "小鼠星型胶质细胞", titleEn: "Mouse astrocytes" },
    ]);
    const key = ref("");
    const pageNum = ref(1);
    const articleTotal = ref(0);
    const articleList1 = ref([]);
    const articleList2 = ref([]);
    const articleList = ref([]);
    const serviceArr = ref([
      {
        label: "急性脑缺血（tMCAO）及药效评价",
        labelEn: "Acute brain ischemia (tMCAO) and efficacy assessment",
        checked: false,
        children: [],
      },
      {
        label: "阿尔兹海默症（AD）及药效评价",
        labelEn: "Alzheimer's disease (AD) and efficacy assessment",
        checked: false,
        children: [],
      },
      {
        label: "糖尿病脑病（DE）及药效评价",
        labelEn: "Diabetic encephalopathy (DE) and efficacy assessment",
        checked: false,
        children: [],
      },
      {
        label: "慢性脑缺血（pMCAO或2VO）及药效评价",
        labelEn:
          "Chronic cerebral ischemia (pMCAO or 2VO) and efficacy assessment",
        checked: false,
        children: [],
      },
      {
        label: "帕金森氏病（PD）及药效评价",
        labelEn: "Parkinson's disease (PD) and efficacy assessment",
        checked: false,
        children: [],
      },
      {
        label: "急慢性抑郁症及药效评价",
        labelEn: "Acute and chronic depression and efficacy assessment",
        checked: false,
        children: [],
      },
      {
        label: "高血压脑病（HE）及药效评价",
        labelEn: "Hypertensive encephalopathy (HE) and efficacy assessment",
        checked: false,
        children: [],
      },
      {
        label: "渐冻症（ALS）及药效评价",
        labelEn: "Amyotrophic lateral sclerosis (ALS) and efficacy assessment",
        checked: false,
        children: [],
      },
      {
        label: "细胞水平药效学评价",
        labelEn: "Cell-level pharmacological assessment",
        checked: false,
        children: [],
      },
      {
        label: "癫痫（Epilepsy）及药效评价",
        labelEn: "Epilepsy and efficacy assessment",
        checked: false,
        children: [],
      },
    ]);
    // const serviceArr = ref([
    //   {
    //     label: "急性脑缺血（tMCAO）及药效评价",
    //     labelEn:
    //       "Acute cerebral ischemia (tMCAO) and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.行为学.梗死体积.生化检测",
    //         labelEn:
    //           "Model selection. Behavior evaluation. Infarct volume. Biochemical analysis",
    //         checked: false,
    //       },
    //       {
    //         label: "脑超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "阿尔兹海默症（AD）及药效评价",
    //     labelEn: "Alzheimer's disease (AD) and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.行为学.生化检测",
    //         labelEn:
    //           "Model selection. Behavior evaluation. Biochemical analysis",
    //         checked: false,
    //       },
    //       {
    //         label: "脑超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "糖尿病脑病（DE）及药效评价",
    //     labelEn: "Diabetic encephalopathy (DE) and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.行为学.血糖血脂.生化检测",
    //         labelEn:
    //           "Model selection. Behavior evaluation. Blood glucose. Blood fat. Biochemical analysis",
    //         checked: false,
    //       },
    //       {
    //         label: "脑超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "慢性脑缺血（pMCAO或2VO）及药效评价",
    //     labelEn:
    //       "Chronic cerebral ischemia (pMCAO or 2VO) and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.行为学.梗死体积.生化检测",
    //         labelEn:
    //           "Model selection. Behavior evaluation. Infarct volume. Biochemical analysis",
    //         checked: false,
    //       },
    //       {
    //         label: "脑超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "帕金森氏病（PD）及药效评价",
    //     labelEn: "Parkinson's disease (PD) and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.行为学.生化检测",
    //         labelEn:
    //           "Model selection. Behavior evaluation. Biochemical analysis",
    //         checked: false,
    //       },
    //       {
    //         label: "脑超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "急慢性抑郁症及药效评价",
    //     labelEn: "Acute and chronic depression and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.行为学.生化检测",
    //         labelEn:
    //           "Model selection. Behavior evaluation. Biochemical analysis",
    //         checked: false,
    //       },
    //       {
    //         label: "脑超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "高血压脑病（HE）及药效评价",
    //     labelEn:
    //       "Hypertensive encephalopathy (HE) and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.合并缺血模型.行为学.梗死体积.血压.心率",
    //         labelEn:
    //           "Model selection. Ischemic stroke model in hypertensive animals. Infarct volume. Blood pressure. Heart rate",
    //         checked: false,
    //       },
    //       {
    //         label: "脑超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "渐冻症（ALS）及药效评价",
    //     labelEn:
    //       "Amyotrophic lateral sclerosis (ALS) and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.行为学.生化检测",
    //         labelEn:
    //           "Model selection. Behavior evaluation. Biochemical analysis",
    //         checked: false,
    //       },
    //       {
    //         label: "脑及脊髓超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain and spinal cord ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "细胞水平药效学评价",
    //     labelEn: "Drug evaluation in cellular and biochemical levels",
    //     checked: false,
    //     children: [
    //       {
    //         label: "多种类型和不同发病阶段的兴奋性氨基酸损伤模型",
    //         labelEn:
    //           "Excitatory amino acid injury models in various types and stages of disease",
    //         checked: false,
    //       },
    //       {
    //         label: "原代神经元或转基因细胞氧糖剥夺损伤模型",
    //         labelEn:
    //           "Oxygen glucose deprivation injury models in primary neurons or trangenic cells",
    //         checked: false,
    //       },
    //       {
    //         label: "LPS炎症损伤模型",
    //         labelEn: "LPS inflammatory injury models",
    //         checked: false,
    //       },
    //       {
    //         label: "星形胶质细胞损伤模型",
    //         labelEn: "Astrocyte injury models",
    //         checked: false,
    //       },
    //       {
    //         label: "少突胶质细胞损伤模型",
    //         labelEn: "Oligodendrocyte injury models",
    //         checked: false,
    //       },
    //       {
    //         label: "流式细胞技术",
    //         labelEn: "Flow cytometry analysis",
    //         checked: false,
    //       },
    //     ],
    //   },
    //   {
    //     label: "癫痫（Epilepsy）及药效评价",
    //     labelEn: "Epilepsy disease ( ED ) and pharmacodynamic evaluation",
    //     checked: false,
    //     children: [
    //       {
    //         label: "模型选择.行为学.脑电.生化检测",
    //         labelEn:
    //           "Model selection. Behavior evaluation. Electroencephalogram. Biochemical analysis",
    //         checked: false,
    //       },
    //       {
    //         label: "脑超微结构（脑血管，神经元，胶质细胞）",
    //         labelEn:
    //           "Brain ultrastructure (cerebral blood vessels, neurons, glias)",
    //         checked: false,
    //       },
    //       {
    //         label: "数据及分析报告（二维/三维定量）",
    //         labelEn:
    //           "Experiment data and Analysis Reports (2D / 3D quantitative analysis)",
    //         checked: false,
    //       },
    //     ],
    //   },
    // ]);
    const notice1Arr = ref([
      {
        label: "成功提交信息后",
        labelEn: "After submitting the information successfully, ",
        labelEnEn:
          "After submitting the information successfully, you will receive our acknowledgment email promptly. If you have any questions during the process, please feel free to contact us at any time.",
      },
      {
        label: "您将立刻收到我们的回执邮件。",
        labelEn: "you will receive our acknowledgment email promptly. ",
      },
      {
        label: "如您在定制过程中有任何疑问，",
        labelEn: "If you have any questions during the customization process,",
      },
      {
        label: "欢迎随时联系我们。",
        labelEn: "please feel free to contact us at any time.",
      },
    ]);
    const formSubmit = ref({
      orderName: undefined,
      experimentPurpose: undefined,
      entrustInstructions: undefined,
      userName: undefined,
      phoneNumber: undefined,
      email: undefined,
      company: undefined,
      idContext: [],
    });
    const initFormSubmit = function () {
      formSubmit.value = {
        orderName: undefined,
        experimentPurpose: undefined,
        entrustInstructions: undefined,
        userName: undefined,
        phoneNumber: undefined,
        email: undefined,
        company: undefined,
        idContext: [],
      };
      serviceArr.value.forEach((item) => {
        item.checked = false;
        item.children.forEach((mm) => {
          mm.checked = false;
        });
      });
    };
    const secondPageRef = ref(null);
    const thirdPageRef = ref(null);
    const aboutLookDom = ref(null);
    const thirdPageMainDom = ref(null);
    const thirdPageMainInnerDom = ref(null);
    const videoLeftDom = ref(null);
    const videoRightDom = ref(null);
    const itemBottom2ImgDom = ref(null);
    const beforeScrollTop = ref(0);
    document.onscroll = function (e) {
      if (router.currentRoute.value.fullPath != "/") {
        return;
      }
      let scrollTop = document.documentElement.scrollTop;
      let allScrollTop = document.documentElement.scrollHeight;
      let ratio = Math.floor((scrollTop / allScrollTop) * 100) / 100;
      let nav = document.querySelector("#nav");
      // nav.style.transition = "all 0.5s";
      // console.log("ratio", ratio);

      if (ratio > 0) {
        if (scrollTop - beforeScrollTop.value > 0) {
          nav.style.opacity = 0; // 下滚
        } else {
          // 上滚
          nav.style.opacity = 1;
          if(homeRef.value) {
            nav.style.backgroundColor = homeRef.value.style.backgroundColor;
          }
          nav.style.borderBottom = "1.5px solid #2d2d2d";
          nav.children[0].children[0].style.color = "#2D2D2D";
          for (let i = 0; i < nav.children[1].children.length; i++) {
            nav.children[1].children[i].children[1].style.color = "#2D2D2D";
          }
          if (scrollTop < 0.3 * document.documentElement.clientHeight) {
            console.log("<0.3");
            isInView.value = false;
            // if (
            //   titleBoxRef.value.children[0].className.includes(
            //     "showLiAnimation1"
            //   )
            // ) {
            //   for (let i = 0; i < titleBoxRef.value.children.length; i++) {
            //     // titleBoxRef.value.children[i].style.opacity = 0;
            //     // titleBoxRef.value.children[i].style.transform =
            //     //   "translateX(33vw)";
            //     let nameClass = "showLiAnimation" + (i + 1);
            //     titleBoxRef.value.children[i].classList.remove(nameClass);
            //   }
            // }
          }
        }
        beforeScrollTop.value = scrollTop;
      }

      if (scrollTop == 0) {
        nav.style.opacity = 1;
        nav.style.backgroundColor = "";
        nav.style.borderBottom = "none";
        nav.children[0].children[0].style.color = "#eae8e4";
        for (let i = 0; i < nav.children[1].children.length; i++) {
          nav.children[1].children[i].children[1].style.color = "#eae8e4";
        }
        beforeScrollTop.value = 0;
      }
      if (scrollTop > 0.39 * document.documentElement.clientHeight) {
        console.log('tdNameRef.value.classList',tdNameRef.value)
        if(tdNameRef.value){
          tdNameRef.value.classList.remove("showTextAnimation1");
        }
        if(searchBoxRef.value){
          searchBoxRef.value.classList.remove("showTextAnimation2");
        }
        
      }
      if (scrollTop < 0.1 * document.documentElement.clientHeight) {
        if(tdNameRef.value){
          tdNameRef.value.classList.add("showTextAnimation1");
        }
        if(searchBoxRef.value){
          searchBoxRef.value.classList.add("showTextAnimation2");
        }
      }

      if (scrollTop > 0.7 * document.documentElement.clientHeight) {
        isInView.value = true;
        // console.log(">0.7");
        // for (let i = 0; i < titleBoxRef.value.children.length; i++) {
        //   let nameClass = "showLiAnimation" + (i + 1);
        //   titleBoxRef.value.children[i].classList.add(nameClass);
        // }
      }
      if (scrollTop > 1.7 * document.documentElement.clientHeight) {
        isInView.value = false;
        // console.log(">1.7");
        // if (
        //   titleBoxRef.value.children[0].className.includes("showLiAnimation1")
        // ) {
        //   for (let i = 0; i < titleBoxRef.value.children.length; i++) {
        //     // titleBoxRef.value.children[i].style.opacity = 0.5;
        //     let nameClass = "showLiAnimation" + (i + 1);
        //     titleBoxRef.value.children[i].classList.remove(nameClass);
        //   }
        // }
      }
      scrollHome(ratio, homeRef.value, videoRightDom.value, videoLeftDom.value);

      // if (
      //   document.documentElement.scrollTop >= 1.1 * oneHeight &&
      //   document.documentElement.scrollTop <= 2.1 * oneHeight
      // ) {
      //   // 每滚动一次颜色变化一次
      //   // thirdPageRef.value.style.backgroundColor="#ab9fdd"
      //   if (!thirdPageRef.value.className.includes("addAnimation")) {
      //     aboutLookDom.value.classList.add("addAboutLookAnimation");
      //     thirdPageRef.value.classList.add("addAnimation");
      //     videoRightDom.value.classList.add("addAnimationVideoRight");
      //     videoLeftDom.value.classList.add("addAnimationVideoLeft");
      //     // thirdPageMainDom.value.classList.add("addAnimationThirdMain");
      //     // thirdPageMainInnerDom.value.classList.add(
      //     //   "addAnimationThirdMainInner"
      //     // );
      //   }
      // } else {
      //   // console.log("动画结束");
      //   // aboutLookDom.value.classList.remove("addAboutLookAnimation");
      //   // thirdPageRef.value.classList.remove("addAnimation");
      //   // videoRightDom.value.classList.remove("addAnimationVideoRight");
      //   // videoLeftDom.value.classList.remove("addAnimationVideoLeft");
      //   // thirdPageMainDom.value.classList.remove("addAnimationThirdMain");
      //   // thirdPageMainInnerDom.value.classList.remove(
      //   //   "addAnimationThirdMainInner"
      //   // );
      // }
    };
    onMounted(() => {
    if(video1Ref){
      video1Ref.value.play();
      tdNameRef.value.classList.add("showTextAnimation1");
      searchBoxRef.value.classList.add("showTextAnimation2");
    }
      
      // itemMove 20s linear 2s alternate infinite;
      // animation: name duration timing-function delay iteration-count direction fill-mode;
      // main2Ref.value.animate([{ marginLeft: "0px" }, { marginLeft: "100%" }], {
      //   duration: "20s",
      //   timingFunction: "linear",
      //   direction: "alternate",
      //   fillMode: "infinite",
      // });
      // main2Ref.value.classList.add("showItemMove");
      // itemTitleListRef.value.classList.add("showItemMove");
      // let animation = main2Ref.value.animate(
      //   [{ transform: "translateX(0px)" }, { transform: "translateX(100px)" }],
      //   {
      //     duration: 10000, // 动画持续时间（单位：毫秒）
      //     delay: 2000, // 动画延迟时间（单位：毫秒）
      //     easing: "linear", // 动画效果
      //     direction: "alternate",
      //     fill: "infinite", // 动画结束后停留在最后一个关键帧上
      //   }
      // );
      // 在动画结束时执行一些操作
      // animation.onfinish = function () {
      //   // 动画结束后的回调函数
      //   console.log("Animation finished!");
      // };
      // 可以通过修改动画对象的属性来控制动画的运行
      // animation.play(); // 播放动画
      // animation.pause(); // 暂停动画
      // animation.reverse(); // 倒放动画
      // searchBoxRef。
      // videoRightDom.value.currentTime = 2;
      // 124.70.8.231:8011
      // `${baseUrl.urlOnline}/website/article/list?pageNum=${pageNum.value}&pageSize=10`;
      getArticles({ pageNum: pageNum.value, pageSize: 10 }).then((res) => {
        // console.log("res", res);
        if (res.data.code == 200) {
          let list = res.data.data.result.records;
          articleList.value = res.data.data.result.records;
          articleTotal.value = res.data.data.result.total;
          articleList1.value = [list[0], list[1], list[2]];

          for (let i = 0; i < 5; i++) {
            articleList2.value.push(list[i]);
          }
        }
      });
    });
    // 加载资源   加载成功   播放结束

    const handleLoadedData = function (key) {
      //视频资源加载
      // console.log("视频资源加载");
    };

    const handleCanPlayThrough = function (key) {
      // 资源加载成功可以播放
      // console.log("资源加载成功可以播放");
    };
    const videoArr = ref([
      "https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/f5db7017de14fba1549795ec5b039588.mp4",
      "https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/2.mp4",
      "https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/Model-20210329-10-brain-neuron488-LEL-dylight594-IS4h-tMCAO-thy1%20TG-5month-TMH-488.mp4",
    ]);
    const videoSrc = ref(
      "https://qcjx.oss-cn-beijing.aliyuncs.com/other/video/f5db7017de14fba1549795ec5b039588.mp4"
    );
    const playNextVideo = function (key) {
      // video1Ref.value.pause();
      // console.log("当前的视频index", videoIndex.value);
      // videoIndex.value = videoIndex.value * 1 + 1;
      // if (videoIndex.value == 2) {
      //   videoIndex.value = 0;
      // }
      // console.log("实际", videoIndex.value, videoArr.value[videoIndex.value]);
      // videoSrc.value = videoArr.value[videoIndex.value * 1];
      // video1Ref.value.play();
      console.log(
        "视频播放结束",
        video1Ref.value,
        video2Ref.value,
        video3Ref.value
      ); // 视频播放结束
      video1Ref.value.style.opacity = 0.01;
      video2Ref.value.style.opacity = 0.01;
      video3Ref.value.style.opacity = 0.01;
      if (key == 1) {
        // 第1个播放结束
        videoIndex.value = 1;
        video2Ref.value.style.opacity = 1;
        video2Ref.value.play();
      } else if (key == 2) {
        // 第2个播放结束
        videoIndex.value = 2;
        video3Ref.value.style.opacity = 1;
        video3Ref.value.play();
      } else if (key == 3) {
        // 第3个播放结束
        videoIndex.value = 0;
        video1Ref.value.style.opacity = 1;
        video1Ref.value.play();
      }
    };
    const handleFocus = function () {
      searchRef.value.style.opacity = 1;
    };
    const handleInput = function () {
      //
    };
    const forKeyScope = function () {
      let flag = false;
      for (let i = 0; i < keyScopeAbout.length; i++) {
        if (keyScopeAbout[i].includes(key.value)) {
          flag = true;
          return { page: "about", index: i };
        }
      }
      if (!flag) {
        for (let i = 0; i < keyScopeTech.length; i++) {
          if (keyScopeTech[i].includes(key.value)) {
            flag = true;
            return { page: "technology", index: i };
          }
        }
        if (!flag) {
          for (let i = 0; i < keyScopeProduct.length; i++) {
            if (keyScopeProduct[i].includes(key.value)) {
              console.log("LPS", i);
              flag = true;
              return { page: "product", index: i };
            }
          }
          if (!flag) {
            for (let i = 0; i < keyScopeHome.length; i++) {
              if (keyScopeHome[i].includes(key.value)) {
                flag = true;
                return { page: "/", index: i };
              }
            }
            if (!flag) {
              return { page: "//", index: -100 };
            }
          }
        }
      }
    };
    // 点击放大镜
    const handleClick = function () {
      // searchRef.value.style.opacity = 0.5;
      magnifierRef.value.style.transform = "scale(1.5,1.5)";
      setTimeout(() => {
        magnifierRef.value.style.transform = "scale(1,1)";
        // `${baseUrl.urlOnline}/website/article/search?keys=${key.value}`
        if (!key.value) {
          alert(
            i18n.global.locale == "ch"
              ? "请输入查询关键字"
              : "Please input key word"
          );
          return;
        }
        getSearchArticle({ keys: key.value }).then((response) => {
          // console.log("response", response);
          if (
            response.data.data.result &&
            response.data.data.result.length == 0
          ) {
            let indexPage = forKeyScope().page ? forKeyScope().page : "/";
            let indexKey = forKeyScope().index ? forKeyScope().index : 0;
            // console.log(indexPage, indexKey);
            switch (indexPage) {
              case "about":
                router.push("/about");
                setTimeout(() => {
                  document.documentElement.scrollTop =
                    document.documentElement.clientHeight * indexKey;
                }, 500);
                break;
              case "technology":
                router.push("/technology");
                setTimeout(() => {
                  if (indexKey == 0) {
                    document.documentElement.scrollTop =
                      document.documentElement.clientHeight * indexKey;
                  } else if (indexKey == 1) {
                    document.documentElement.scrollTop =
                      document.documentElement.clientHeight * indexKey +
                      document.documentElement.clientHeight / 10;
                  } else if (indexKey == 2) {
                    document.documentElement.scrollTop =
                      document.documentElement.clientHeight * indexKey -
                      document.documentElement.clientHeight / 4;
                  } else if (indexKey == 3) {
                    document.documentElement.scrollTop =
                      document.documentElement.clientHeight * indexKey -
                      document.documentElement.clientHeight / 1.2;
                  } else if (indexKey == 4) {
                    document.documentElement.scrollTop =
                      document.documentElement.clientHeight * indexKey -
                      document.documentElement.clientHeight * 1.2;
                  } else if (indexKey == 5) {
                    document.documentElement.scrollTop =
                      document.documentElement.clientHeight * indexKey -
                      document.documentElement.clientHeight * 2;
                  }
                }, 500);
                break;
              case "product":
                router.push("/product");
                setTimeout(() => {
                  document.documentElement.scrollTop =
                    document.documentElement.clientHeight * indexKey;
                }, 500);
                break;
              case "/":
                router.push("/");
                setTimeout(() => {
                  document.documentElement.scrollTop =
                    document.documentElement.clientHeight * indexKey + 10;
                }, 500);
                break;
              case "//":
                key.value = "";
                alert(
                  i18n.global.locale == "ch"
                    ? "暂无查询信息"
                    : "There is currently no query information available"
                );
                break;
            }
          } else if (
            response.data.data.result &&
            response.data.data.result.length == 1
          ) {
            window.open(response.data.data.result[0].articleChain, "_blank");
          } else if (
            response.data.data.result &&
            response.data.data.result.length > 1
          ) {
            router.push({
              path: "/searchArticle",
              query: { key: key.value },
            });
          }
        });
      }, 500);
    };
    const handleBlur = function () {
      // console.log("handleBlur", searchRef.value);
      if (searchRef.value) {
        searchRef.value.style.opacity = 0.5;
      }
    };
    const main2RefMouseEnter = function (e) {
      main2Ref.value.style.animationPlayState = "paused";
    };
    const main2RefMouseLeave = function (e) {
      main2Ref.value.style.animationPlayState = "running";
    };
    // down - 手动位移
    const main2RefMouseDown = function (e) {
      // mainOuterRef
      e.preventDefault();
      mainOuterInnerRef.value.style.transition = "all 0s";
      itemTitleListRef.value.style.transition = "all 0s";
      downX.value = e.x;

      let itemMarginLeft = parseInt(
        getComputedStyle(mainOuterInnerRef.value.children[0].children[3])
          .marginLeft
      );
      marginLeft.value = parseInt(
        getComputedStyle(mainOuterInnerRef.value).marginLeft
      );
      maxLeftLeft.value = -(
        mainOuterInnerRef.value.children[0].clientWidth * 3 +
        itemMarginLeft * 3
      );
      console.log("main2RefMouseDown", marginLeft.value);
      // console.log(
      //   "down",
      //   main2Ref.value.children[0].clientWidth,
      //   getComputedStyle(main2Ref.value.children[3]).marginRight
      // );
    };
    const main2RefMouseMove = function (event) {
      // for (let i = 0; i < liRefArr.value.length; i++) {
      //   liRefArr.value[i].style.opacity = 1;
      //   liRefArr.value[i].style.transform = "translateX(0px)";
      // }

      if (downX.value != 0) {
        let left = marginLeft.value + event.x - downX.value;
        // console.log(
        //   "move",
        //   maxLeftLeft.value / 3,
        //   main2Ref.value.clientWidth / 4
        // );
        if (left > 0) {
          currentTitleIndex.value = 0;
          mainOuterInnerRef.value.style.marginLeft = "0px";
          itemTitleListRef.value.style.marginLeft = "0px";
        } else if (left < maxLeftLeft.value) {
          currentTitleIndex.value = 3;
          mainOuterInnerRef.value.style.marginLeft = maxLeftLeft.value + "px";
          itemTitleListRef.value.style.marginLeft = maxLeftLeft.value + "px";
        } else {
          // maxLeftLeft.value/3 负数 单个item width + marginLeft
          if (left <= (-mainOuterInnerRef.value.clientWidth / 5) * 3.4) {
            currentTitleIndex.value = 3;
            // mainOuterInnerRef.value.children[2].children[1].style.display =
            //   "none";
          } else if (
            left <=
            (-mainOuterInnerRef.value.clientWidth / 5) * 2.15
          ) {
            currentTitleIndex.value = 2;
            //   mainOuterInnerRef.value.children[1].children[1].style.display =
            //     "none";
            //   mainOuterInnerRef.value.children[3].children[1].style.display =
            //     "none";
            // } else if (left <= (-mainOuterInnerRef.value.clientWidth / 5) * 1) {
            //   currentTitleIndex.value = 1;
            //   mainOuterInnerRef.value.children[0].children[1].style.display =
            //     "none";
            //   mainOuterInnerRef.value.children[2].children[1].style.display =
            //     "none";
          } else {
            currentTitleIndex.value = 0;
            // mainOuterInnerRef.value.children[1].children[1].style.display =
            //   "none";
            // mainOuterInnerRef.value.children[2].children[1].style.display =
            //   "none";
          }
          mainOuterInnerRef.value.style.marginLeft = left + "px";
          itemTitleListRef.value.style.marginLeft = left + "px";
        }
      }
    };
    const main2RefMouseUp = function (e) {
      // console.log("up");
      downX.value = 0;
    };
    // 点击4张轮播图的文字
    const titleMouseOverHanlder = function (e) {
      // let mirrowArr = document.querySelectorAll(".mirrow");
      // for (let i = 0; i < mirrowArr.length; i++) {
      //   mirrowArr[i].style.display = "none";
      // }
      deleteMirrow();
      let title_id = e.target.dataset.id;
      currentTitleIndex.value = title_id;
      switch (true) {
        case title_id == 0:
          swiperInstance.value.slideTo(2);
          return;
        case title_id == 1:
          swiperInstance.value.slideTo(3);
          return;
        case title_id == 2:
          swiperInstance.value.slideTo(4);
          return;
        case title_id == 3:
          swiperInstance.value.slideTo(5);
          return;
      }
      let translatex = -e.target.dataset.id * 60.5 + "vw";
      // console.log("titleMouseOverHanlder", e.target.dataset.id, translatex);
      // main2Ref.value.style.marginLeft = translatex;
      // itemTitleListRef.value.style.marginLeft = translatex;

      // main2Ref.value.style.transition = "all 1s";

      // itemTitleListRef.value.style.transition = "all 1s";
      // for (let i = 0; i < liRefArr.value.length; i++) {
      //   liRefArr.value[i].style.opacity = 1;
      //   liRefArr.value[i].style.transform = "translateX(0px)";
      // }

      // main2Ref.value.style.transform = `translateX(-${translatex})`;
      // itemTitleListRef.value.style.transform = `translateX(-${translatex})`;
    };

    const secondPage2Dom = ref(null);
    let smallImgDom = ref(null);
    let mirrowDom = ref(null);
    let bigImgDom = ref(null);
    let secondPage2DomRect = ref(null);
    let smallImgDomRect = ref(null);
    let titleHeight = ref(0);
    const deleteMirrow = function () {
      let mirrowArr = document.querySelectorAll(".mirrow");
      for (let i = 0; i < mirrowArr.length; i++) {
        mirrowArr[i].style.display = "none";
      }
    };
    // 鼠标移入slide
    const imgMouseEnter = function (e, index) {
      // console.log("imgMouseEnter");
      deleteMirrow();
      titleHeight.value = document.querySelector(".itemTitle").offsetHeight;
      e.target.children[1].style.display = "block";
      secondPage2Dom.value = document.querySelector("#secondPageTwo");
      smallImgDom.value = e.target.children[0];
      mirrowDom.value = e.target.children[1];
      bigImgDom.value = e.target.children[1].children[0];
      secondPage2DomRect.value = secondPage2Dom.value.getBoundingClientRect();
      smallImgDomRect.value = smallImgDom.value.getBoundingClientRect();
    };
    // 鼠标在小图内移动
    const imgMouseMove = function (e, index) {
      let mirrowLeft =
        e.clientX -
        smallImgDomRect.value.left -
        mirrowDom.value.offsetWidth / 2;
      let mirrowTop =
        e.clientY -
        secondPage2DomRect.value.top -
        mirrowDom.value.offsetHeight / 2;
      // console.log("imgMouseMove", mirrowLeft, mirrowTop);
      mirrowDom.value.style.left = mirrowLeft + "px";
      mirrowDom.value.style.top = mirrowTop + "px";

      // if (
      //   e.clientX - smallImgDomRect.value.left < 0 ||
      //   e.clientX - smallImgDomRect.value.left >
      //     smallImgDom.value.offsetWidth ||
      //   e.clientY - secondPage2DomRect.value.top < -5 ||
      //   e.clientY - smallImgDomRect.value.top > smallImgDom.value.offsetHeight
      // ) {
      //   mirrowDom.value.style.display = "none";
      // }

      // 左右边界处理
      if (
        e.clientX - smallImgDomRect.value.left < 0 ||
        e.clientX - smallImgDomRect.value.left > smallImgDom.value.offsetWidth
      ) {
        mirrowDom.value.style.display = "none";
      }
      // 计算大图 Left
      let bigImgLeft =
        ((mirrowLeft + mirrowDom.value.offsetWidth / 2) /
          smallImgDom.value.offsetWidth) *
          bigImgDom.value.offsetWidth -
        mirrowDom.value.offsetWidth / 2;
      let bigImgTop =
        ((mirrowTop + mirrowDom.value.offsetHeight / 2) /
          smallImgDom.value.offsetHeight) *
          bigImgDom.value.offsetHeight -
        mirrowDom.value.offsetHeight / 2;
      bigImgDom.value.style.left = -bigImgLeft + "px";
      bigImgDom.value.style.top = -bigImgTop + "px";
    };
    // 鼠标离开小图
    const imgMouseLeave = function (e, index) {
      deleteMirrow();
      // console.log("imgMouseLeave");
    };
    // hover
    const itemTopimgAbsoluteMouseEnter = function (e) {
      e.target.children[1].style.width = "0px";
      e.target.children[1].style.opacity = 1;
      setTimeout(() => {
        e.target.children[0].style.filter = "blur(4px)";
        e.target.children[1].style.width = e.target.clientWidth * 0.7 + "px";
        e.target.children[1].style.transition = "all 0.8s";
      }, 20);
    };
    const itemTopimgAbsoluteMouseLeave = function (e) {
      e.target.children[0].style.filter = "blur(0px)";
      e.target.children[1].style.width = "0px";
    };
    // 点击
    const itemBottom2ImgMouseEnter = function (e) {
      e.target.src = circleInIn;
      e.target.style.transform = "scale(1.5,1.5)";
      e.target.style.transition = "all 0.5s";
    };
    const itemBottom2ImgMouseLeave = function (e) {
      e.target.src = circleIn;
      e.target.style.transform = "scale(1,1)";
      e.target.style.transition = "all 0.8s";
    };
    //
    const item2InnerMouseEnter = function (e) {
      // console.log(e.target.nextElementSibling, e.target.offsetHeight);
      // e.target.nextElementSibling.style.opacity = 1;
      // e.target.nextElementSibling.style.top =
      //   -(
      //     e.target.offsetHeight +
      //     e.target.nextElementSibling.offsetHeight / 4
      //   ) + "px";
      // e.target.nextElementSibling.style.left =
      //   e.target.offsetWidth * 1.1 + "px";
    };

    const item2InnerMouseMove = function (index, e) {
      squareImgRef.value.style.opacity = 1;
      let fatherTop = main2BottomRef.value.getBoundingClientRect().top;
      // console.log("item2InnerMouseMove", index);

      squareImgRef.value.src = articleList2.value[index].squarePic
        ? "http://www.qianchengjx.com" + articleList2.value[index].squarePic
        : articleList2.value[index].mouseOverPic
        ? "http://www.qianchengjx.com" + articleList2.value[index].mouseOverPic
        : "http://www.qianchengjx.com" +
          articleList2.value[index].articlePicture;

      squareImgRef.value.style.left =
        (e.x / document.documentElement.clientWidth + 0.02) * 100 + "%";

      squareImgRef.value.style.top =
        ((e.y - fatherTop) / main2BottomRef.value.clientHeight) * 100 -
        squareImgRef.value.clientHeight / 3 +
        "%";
    };
    const item2InnerMouseLeave = function (e) {
      squareImgRef.value.src = "";
      squareImgRef.value.style.opacity = 0;
      // console.log(e.target);
      // e.target.nextElementSibling.style.opacity = 0;
    };
    // 点击单个文章查看详情
    const handleToDetail = function (url) {
      window.open(url, "_blank");
    };
    //
    const checkMoreRef = ref(null);
    const main2Bottom22Enter = function (e) {
      checkMoreRef.value.src = circleInIn;
    };
    const main2Bottom22Leave = function (e) {
      // console.log("main2Bottom22Leave");
      checkMoreRef.value.src = circleIn;
    };
    const toHotArticles = function (e) {
      router.push("/article");
    };
    //
    const itemCheckedChange = function (item) {
      // console.log("itemCheckedChange", item);
      item.children.forEach((obj) => {
        obj.checked = item.checked;
      });
    };
    const mCheckedChange = function (item, m) {
      let isAllTrue = item.children.every((obj) => {
        return obj.checked;
      });
      // console.log("isAllTrue", isAllTrue);
      item.checked = isAllTrue;
    };
    const validateEmail = function (email) {
      const reg =
        /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*\.[A-Za-z]{2,}$/;
      return reg.test(email);
    };
    const validatePhoneNum = function (phoneNumber) {
      const rule = /^1[3456789]\d{9}$/;
      return rule.test(phoneNumber);
    };
    // 提交订单
    const handleSubmitOrder = function (e) {
      let currentLanguage = i18n.global.locale;
      let msg = "";
      if (
        !formSubmit.value.orderName ||
        !formSubmit.value.userName ||
        !formSubmit.value.phoneNumber ||
        !formSubmit.value.email ||
        !formSubmit.value.company
      ) {
        msg =
          currentLanguage == "ch"
            ? "请填写必填项 !"
            : "Please fill in the required fields!";
        alert(msg);
        return;
      } else if (!validatePhoneNum(formSubmit.value.phoneNumber)) {
        msg =
          currentLanguage == "ch"
            ? "请填写正确格式的手机号码!"
            : "Please fill in your phone number in the correct format!";
        alert(msg);
      } else if (!validateEmail(formSubmit.value.email)) {
        msg =
          currentLanguage == "ch"
            ? "请填写正确格式的邮箱地址!"
            : "Please fill in your email address in the correct format!";
        alert(msg);
      } else {
        loading.value = true;
        let tempArr = JSON.parse(JSON.stringify(serviceArr.value));
        // 筛选目标
        // let tempArr1 = tempArr.filter((objBig) => {
        //   return objBig.children.some((obj) => {
        //     return obj.checked;
        //   });
        // });
        let tempArr1 = tempArr.filter((objBig) => {
          return objBig.checked;
        });
        // 筛选目标 -删除checked
        tempArr1.forEach((objBig) => {
          delete objBig.checked;
          // let newChildren = objBig.children.reduce((arr, objSmall) => {
          //   let temp = [].concat(arr);
          //   if (objSmall.checked) {
          //     temp.push({ label: objSmall.label });
          //   }
          //   return temp;
          // }, []);
          // objBig.children = newChildren;
        });
        formSubmit.value.idContext = tempArr1;
        // console.log("formSubmit.value", formSubmit.value);
        submitOrder(formSubmit.value).then((response) => {
          console.log("提交订单response", response);
          loading.value = false;
          if (response.data.code == 200) {
            initFormSubmit();
            msg =
              currentLanguage == "ch"
                ? "提交成功，请查看邮箱 !"
                : "submit successfully,please check your email!";
            alert(msg);
          } else {
            msg =
              currentLanguage == "ch"
                ? "网络出小差，请稍后再试 ！!"
                : "Network error,please try later!";
            alert(msg);
          }
        });
      }
    };
    const swiperActiveIndex = ref(0);
    const swiperInstance = ref(null);
    const onSwiper = (swiper) => {
      // console.log("swiper", swiper);
      swiperInstance.value = swiper;
    };
    const onSlideChange = (swiper) => {
      swiperActiveIndex.value = swiper.activeIndex;
      // console.log("slide change", swiper);
    };
    const pauseAutoplay = () => {
      if (swiperInstance.value) {
        swiperInstance.value.autoplay.stop();
      }
    };

    const startAutoplay = () => {
      if (swiperInstance.value) {
        swiperInstance.value.autoplay.start();
      }
    };
    const handleTitleActive = function (index) {
      switch (true) {
        case index == 0 &&
          (swiperActiveIndex.value == 2 || swiperActiveIndex.value == 6):
          return true;
        case index == 1 && swiperActiveIndex.value == 3:
          return true;
        case index == 2 && swiperActiveIndex.value == 4:
          return true;
        case index == 3 &&
          (swiperActiveIndex.value == 1 || swiperActiveIndex.value == 5):
          return true;
      }
    };

    return {
      homeRef,
      tdNameRef,
      searchBoxRef,
      video1Ref,
      video2Ref,
      video3Ref,
      videoPlayer,
      outerRef,
      searchRef,
      magnifierRef,
      videoIndex,
      titleBoxRef,
      serviceArr,
      handleLoadedData,
      handleCanPlayThrough,
      playNextVideo,
      handleFocus,
      handleInput,
      handleClick,
      handleBlur,
      mainOuterRef,
      mainOuterInnerRef,
      itemTitleListRef,
      main2Ref,
      titleArr,
      currentTitleIndex,
      mirrowRef,
      titleMouseOverHanlder,
      main2RefMouseEnter,
      main2RefMouseLeave,
      main2RefMouseDown,
      main2RefMouseMove,
      main2RefMouseUp,
      imgMouseEnter,
      imgMouseMove,
      imgMouseLeave,
      thirdPageRef,
      aboutLookDom,
      thirdPageMainDom,
      thirdPageMainInnerDom,
      videoLeftDom,
      videoRightDom,
      itemBottom2ImgDom,
      itemBottom2ImgMouseEnter,
      itemBottom2ImgMouseLeave,
      itemTopimgAbsoluteMouseEnter,
      itemTopimgAbsoluteMouseLeave,
      item2InnerMouseEnter,
      item2InnerMouseMove,
      item2InnerMouseLeave,
      toHotArticles,
      checkMoreRef,
      main2Bottom22Enter,
      main2Bottom22Leave,
      notice1Arr,
      itemCheckedChange,
      mCheckedChange,
      formSubmit,
      handleSubmitOrder,
      pageNum,
      articleTotal,
      articleList,
      articleList1,
      articleList2,
      handleToDetail,
      key,
      fifthPageRef,
      secondPageRef,
      fourthPageRef,
      submit1Ref,
      loading,
      main2BottomRef,
      squareImgRef,
      pauseAutoplay,
      startAutoplay,
      modules: [FreeMode, Navigation, Thumbs, A11y, Autoplay],
      onSwiper,
      onSlideChange,
      swiperActiveIndex,
      handleTitleActive,
      isInView,
      videoArr,
      videoSrc,
    };
  },
};
</script>
<style scoped lang="scss">
// @keyframes itemMove {
//   0% {
//     transform: translateX(0%);
//     // margin: 0px;
//   }
//   100% {
//     transform: translateX(-66%);
//     // margin-left: -200%;
//   }
// }

@keyframes itemMove {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-66%);
  }
}

.showItemMove {
  animation: itemMove 20s linear 2s alternate infinite;
  // animation: itemMove 10s linear 2s infinite;
}
// .showTitleMove {
//   animation: titleMove 8s linear 2s alternate infinite;
// }
@keyframes showText1 {
  0% {
    top: 36vh;
    opacity: 0;
  }
  100% {
    top: 30vh;
    opacity: 1;
  }
}
@keyframes showText2 {
  0% {
    opacity: 0;
    top: 46.5vh;
  }
  100% {
    opacity: 1;
    top: 40.5vh;
  }
}
.showTextAnimation1 {
  animation: showText1 1s ease 0.5s forwards;
}
.showTextAnimation2 {
  animation: showText2 1s ease 1.1s forwards;
}
@keyframes showLi {
  0% {
    opacity: 0;
    transform: translateX(33vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0vw);
  }
}
.showLiAnimation1 {
  animation: showLi 1s ease forwards;
}
.showLiAnimation2 {
  animation: showLi 1s ease 0.2s forwards;
}
.showLiAnimation3 {
  animation: showLi 1s ease 0.4s forwards;
}
.showLiAnimation4 {
  animation: showLi 1s ease 0.6s forwards;
}
@keyframes move {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes aboutLookAnimation {
  100% {
    color: #cdf43d;
  }
}
@keyframes thirdPageMainAnimation {
  100% {
    border: 1px solid #2680eb;
    border-bottom: none;
    background-color: #4f24ee;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
}
// 玻璃压花效果不要了
@keyframes thirdPageMainInnerAnimation {
  18% {
    background-image: url("./../assets/try.png");
    background-size: 96vw;
    opacity: 0.3;
  }
  36% {
    background-image: url("./../assets/try.png");
    background-size: 96vw;
    opacity: 0.8;
  }
  54% {
    background-image: url("./../assets/try.png");
    background-size: 96vw;
    opacity: 1;
  }
  72% {
    background-image: url("./../assets/try.png");
    background-size: 96vw;
    opacity: 1;
  }
  90% {
    background-image: url("./../assets/try.png");
    background-size: 96vw;
    opacity: 0.6;
    filter: blur(3px);
  }

  100% {
    background-image: url("./../assets/try.png");
    background-size: 96vw;
    filter: blur(9px);
  }
}
@keyframes videoRightAnimation {
  100% {
    width: 42vw;
    top: 10vh;
    right: 2vw;
  }
}
@keyframes videoLeftAnimation {
  100% {
    width: 42vw;
    top: 24vh;
    left: 2vw;
  }
}

.addAboutLookAnimation {
  animation: aboutLookAnimation 3s linear forwards;
}
.addAnimationThirdMain {
  animation: thirdPageMainAnimation 3s linear forwards;
}
.addAnimationThirdMainInner {
  animation: thirdPageMainInnerAnimation 3s linear forwards;
}
.addAnimationVideoRight {
  animation: videoRightAnimation 3s linear forwards;
}
.addAnimationVideoLeft {
  animation: videoLeftAnimation 3s linear forwards;
}
.firstPage {
  width: 100%;
  height: 100vh;
  .videoBox {
    width: 100vw;
    height: 100vh;
    background-position: center;
    .video1,
    .video2,
    .video3 {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: fill;
    }
  }
  .outer {
    position: absolute;
    top: 8.5vh;
    left: 0;
    width: 100%;
    height: 10vh;
    .main {
      display: flex;
      width: max-content;
      .item {
        flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
        height: 10vh;
        // font-size: 100px;
        font-size: 4.88vw;
        font-weight: 800;
        color: #eae8e4;
        animation: move 4s linear infinite;
        span {
          display: inline-block;
        }
        .th {
          height: 10vh;
          line-height: 10vh;
        }
        .di {
          height: 10vh;
          line-height: 10vh;
        }
        .iconfont {
          height: 10vh;
          line-height: 10vh;
          // font-size: 79px;
          font-size: 3.8vw;
          color: #eae8e4;
          vertical-align: top;
          padding-left: 1vw;
          padding-right: 1vw;
        }
      }
    }
  }
  .td_name {
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    position: absolute;
    top: 30vh;
    // left: 292px;
    left: 14.3vw;
    // font-size: 39px;
    font-size: 1.91vw;
    font-weight: 500;
    color: #eae8e4;
    opacity: 0;
  }
  .searchBox {
    display: flex;
    align-items: center;
    position: absolute;
    top: 41.5vh;
    // left: 292px;
    left: 14.3vw;
    opacity: 0;
    .searchInputBox {
      display: inline-block;
      .searchInput {
        box-sizing: border-box;
        // width: 400px;
        width: 19.5vw;
        height: 5vh;
        // font-size: 32px;
        font-size: 1.55vw;
        border-radius: 33px;
        border: none;
        outline: none;
        opacity: 0.5;
        padding-left: 1.175vw;
        padding-right: 1.175vw;
        color: #3d3d3d;
        transition: all 0.5s;
      }
      outline-width: 7px;
      outline-style: solid;
      outline-offset: 0px;
      border-radius: 33px;
    }

    .magnifier {
      width: 2.8vw;
      vertical-align: top;
      margin-left: 0.98vw;
      transition: all 0.5s;
      cursor: pointer;
    }
  }
}
.secondPage1 {
  // width: 900px;
  width: 43.95vw;
  margin: 0vh auto 11vh auto;
  padding-top: 13vh;
  .line {
    // border-top: 1px solid #2d2d2d;
    border-top: 0.06vw solid #2d2d2d;
  }
  .line2 {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5vh;
    .left {
      .iconfont {
        color: #707070;
        vertical-align: middle;
      }
      .iconfont1 {
        // font-size: 25px;
        font-size: 1.22vw;
      }
      .iconfont2 {
        // font-size: 29px;
        font-size: 1.43vw;
        margin-left: 0.35vw;
      }
    }
    .right {
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #2d2d2d;
      font-size: 0.88vw;
    }
  }
}
.secondPage {
  position: relative;
  // height: 100vh;
  transition: background-color 0.2s;
  .secondPage2 {
    position: relative;
    width: 93vw;
    margin-left: 7vw;
    .titleBox {
      position: absolute;
      left: 60.5vw;
      z-index: 100;
      // width: 32.7vw;
      // height: 74.5vh;
      .commonLi {
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-size: 2.15vw;
        font-weight: 500;
        color: #fff;
        text-align: left;
        padding-top: 1.5vh;
        letter-spacing: 0.11vw;
        // transform: translateX(33vw);
        opacity: 0;
        cursor: pointer;
      }
      .commonLiEn {
        font-size: 1.3vw;
      }
      .titleActive {
        -webkit-text-stroke: 0.1vw #e92ea3;
        color: #000000;
        -webkit-text-fill-color: transparent;
      }
    }
    .box {
      position: relative;
      width: 100%;
      .itemImg {
        width: 100%;
      }

      .mirrow {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 12vw;
        height: 12vw;
        border-radius: 50%;
        border: 12px solid #e92ea3;
        overflow: hidden;
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .itemTitle {
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-size: 1.03vw;
        font-size: 1.5vw;
        font-weight: 500;
        color: #2d2d2d;
        text-align: left;
        // padding-top: 1.5vh;
        // margin-left: 2.5vw;
      }
    }
    .mainOuter {
      width: 96vw;
      .outerInner {
        width: max-content;
      }
      .main {
        display: flex;
        width: max-content;
        .item {
          position: relative;
          width: 58vw;
          margin-left: 2.5vw;

          // animation: name duration timing-function delay iteration-count direction fill-mode;
          .itemImg {
            width: 58vw;
          }

          .mirrow {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 12vw;
            height: 12vw;
            border-radius: 50%;
            border: 12px solid #e92ea3;
            overflow: hidden;
            img {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .itemTitle {
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-size: 1.03vw;
            font-size: 1.5vw;
            font-weight: 500;
            color: #2d2d2d;
            text-align: left;
            width: 58vw;
            padding-top: 1.5vh;
            // margin-left: 2.5vw;
          }
        }
        .item:last-child {
          margin-right: 3vw;
        }
      }
      // .main:hover {
      //   animation-play-state: paused;
      // }
    }

    .itemTitleList {
      display: flex;
      width: max-content;
      padding-top: 1.5vh;
      padding-bottom: 6vh;
      .itemTitle {
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-size: 1.03vw;
        font-size: 2vw;
        font-weight: 500;
        color: #2d2d2d;
        text-align: left;
        width: 58vw;
        margin-left: 2.5vw;
      }
    }
  }
}
.thirdPage {
  // height: 100vh;
  // background: #eae8e4;
  transition: background-color 0.2s;
  .thirdPage1 {
    margin-bottom: 1.5vh;
  }
  .main {
    position: relative;
    width: 96vw;
    height: 81.5vh;
    margin: 0 auto;

    .mainInner {
      width: 96vw;
      height: 81.5vh;
    }

    .videoLeft {
      position: absolute;
      width: 0vw;
      top: 38vh;
      left: 43vw;
      transition: all 1s;
      // animation: videoLeftAnimation 5s linear forwards;
    }
    .videoRight {
      position: absolute;
      width: 0vw;
      top: 32vh;
      right: 43vw;
      transition: all 1s;
      // animation: videoRightAnimation 5s linear forwards;
    }
  }
}
.fourthPage {
  width: 100%;
  transition: background-color 0.2s;
  .main1 {
    display: flex;
    width: 96vw;
    margin-left: 2.15vw;
    overflow-x: hidden;
    .item {
      width: 32vw;
      margin-right: 2.8vw;
      cursor: pointer;
      .itemTop {
        position: relative;
        width: 30vw;
        .itemTopimg {
          width: 30vw;
        }
        .itemTopimgAbsolute {
          position: absolute;
          // width: 0vw;
          width: 21vw;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.01;
        }
      }
      .itemBottom {
        span {
          display: inline-block;
          text-align: left;
        }
        .itemBottom1 {
          width: 100%;
          font-size: 0.732vw;
          font-weight: 400;
          color: #2d2d2d;
          margin-top: 1.5vh;
        }
        .itemBottom2 {
          font-size: 1.56vw;
          margin-top: 1.5vh !important;
          text-align: left;
          color: #2d2d2d;
        }
        .itemBottom3 {
          font-size: 1.025vw;
          font-size: 1.02vw;
          font-weight: 400;
          color: #2d2d2d;
          // height: 7vh;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
        }
        .itemBottom2Img {
          // position: relative;
          // top: 0.2vw;
          width: 1.5vw;
          height: 1.5vw;
        }
        .itemBottom4 {
          padding-bottom: 1vh;
        }
      }
    }
  }
  .main2 {
    width: 96vw;
    margin-top: 13vh;
    margin-left: 2.15vw;
    span {
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      display: inline-block;
      text-align: left;
      font-size: 0.88vw;
      font-weight: 500;
      color: #2d2d2d;
    }
    .main2Top {
      width: 100%;
      padding-bottom: 2vh;
      .main2Top1 {
        width: 23vw;
      }
      .main2Top2 {
        width: 63vw;
      }
      .main2Top3 {
        width: 10vw;
      }
    }
    .main2Bottom {
      position: relative;
      width: 100%;
      padding-top: 1.3vh;
      .squareImg {
        position: absolute;
        width: 7vw;
        // border: 1px solid red;
        border-radius: 5%;
        z-index: 1000;
      }
      .main2BottomItem {
        width: 100%;
        // padding-top: 2vh;
        // padding-bottom: 2vh;
        border-bottom: 1px solid #2d2d2d;
        // background: yellow;
        cursor: pointer;
      }
      .item1 {
        width: 23vw;
      }
      .item2 {
        position: relative;
        width: 53vw;
        padding-top: 2vh;
        padding-bottom: 2vh;
        // background: pink;
        .item2Inner {
          // cursor: pointer;
        }
        .item2Img {
          position: absolute;
          width: 10vw;
          opacity: 0;
          transition: all 0.1s;
        }
      }
      .item3 {
        width: 10vw;
      }
      .item4 {
        width: 10vw;
      }
    }
    .main2Bottom2 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 2vh;
      padding-bottom: 18vh;
      .main2Bottom21 {
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-size: 0.88vw;
        font-weight: 500;
        color: #2d2d2d;
        cursor: pointer;
      }
      .main2Bottom22 {
        width: 1.5vw;
        height: 1.5vw;
        margin: 0 5vw 0 0.5vw;
        cursor: pointer;
        transition: all 0.5s;
      }
      .main2Bottom22:hover {
        transform: scale(1.5);
      }
    }
  }
}
.fifthPage {
  width: 100%;
  transition: background-color 0.2s;

  .main1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 84vw;
    margin: 0 auto;
    span {
      display: inline-block;
    }
    .itemBox {
      width: 31%;
      text-align: left;
      border-radius: 15px;
      padding: 1vw 0vw 1vw 1vw;
      background-color: #fff;
      .itemTop {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .itemTop1 {
          width: 2.3vw;
          height: 2.3vw;
          .itemTop11 {
            // display: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            -o-appearance: none;
            -ms-appearance: none;
            position: relative;
            width: 1.3vw;
            height: 1.3vw;
            border: 1.5px solid #707070;
            margin: 0.5vw;
            background-color: #eae8e4;
            cursor: pointer;
          }
          .itemTop11:checked {
            color: #fff;
            background-color: #add800;
            background-image: url("./../assets/gou.png");
            background-size: 100%;
          }
        }

        .itemTop2 {
          position: relative;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-size: 1.27vw;
          font-weight: bold;
          color: #2d2d2d;
        }
      }
      .itemBottom {
        .itemBottomItem {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .itemBottomItemLeft {
            width: 2.3vw;
            height: 2.3vw;
            .itemBottomItemLeftInput {
              -moz-appearance: none;
              -webkit-appearance: none;
              -o-appearance: none;
              -ms-appearance: none;
              width: 1.1vw;
              height: 1.1vw;
              border: 1.5px solid #707070;
              background-color: #eae8e4;
              margin: 0.6vw;
              cursor: pointer;
            }
            .itemBottomItemLeftInput:checked {
              color: #fff;
              background-color: #add800;
              background-image: url("./../assets/gou.png");
              background-size: 100%;
            }
          }

          .itemBottomItemRight {
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-size: 0.88vw;
            font-weight: bold;
            color: #2d2d2d;
          }
        }
      }
    }
    .itemBox:hover {
      box-shadow: 0 0 12px 0px #595959, 0 0 0 2px #595959;
    }
  }
  .main1 .itemBox:nth-child(n + 4) {
    margin-top: 2.4vh;
  }
  .main1 .itemBox:last-child {
    // margin-top: -11.1vh;
    margin-left: 28.5vw;
  }
  .main2 {
    display: flex;
    justify-content: space-between;
    width: 84vw;
    margin: 0 auto;
    margin-top: 2.4vh;
    padding-bottom: 27vh;
    .main20 {
      width: 31%;
      border-radius: 15px;
      padding: 1vw 0vw 1vw 1vw;
      background-color: #cdf43d;
    }
    .main21:hover {
      // outline: 2px solid #fff;
      box-shadow: 0 0 12px 0px #595959, 0 0 0 2px #595959;
    }
    .main22:hover {
      // outline: 2px solid #fff;
      box-shadow: 0 0 12px 0px #595959, 0 0 0 2px #595959;
    }
    .main23:hover {
      // outline: 2px solid #fff;
      box-shadow: 0 0 12px 0px #595959, 0 0 0 2px #595959;
    }
    .main22H2 {
      color: #2d2d2d;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      text-align: left;
      padding-left: 0.5vw;
    }

    .main21 {
      .main211 {
        font-size: 1.2vw;
        color: #2d2d2d;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        text-align: left;
        padding-left: 0.5vw;
      }
      .main212 {
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-size: 18px;
        font-weight: 500;
        color: #2d2d2d;
        text-align: left;
        padding-left: 0.5vw;
      }
      .main2121 {
        padding-top: 3vh;
      }
      .main2122 {
        padding-top: 1vh;
      }
      .main2123 {
        padding-top: 1vh;
      }
    }
    .main21 .main211:nth-child(n + 2) {
      padding-top: 1.5vh;
    }
    .main22 {
      .main22H4 {
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-size: 0.88vw;
        // font-weight: 500;
        color: #2d2d2d;
        text-align: left;
        padding-top: 1.5vh;
        padding-left: 4vw;
        padding-right: 4vw;
      }
      .main22Purpose {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 1.5vh;
        padding-left: 4vw;
        span {
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          display: inline-block;
          width: 6.5vw;
          font-size: 0.93vw;
          font-weight: 500;
          color: #2d2d2d;
        }
        textarea {
          width: 12vw !important;
          height: 1vw !important;
          font-size: 0.88vw;
          // margin-left: 0.5vw;
          padding: 0.5vw;
          border-radius: 8px;
          resize: none;
          border-radius: 7px;
          border: 1.7px solid #2d2d2d;
          outline: none;
          color: #2d2d2d;
          overflow: hidden;
        }
      }
      .main22Remark {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 1.5vh;
        padding-left: 4vw;
        span {
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          display: inline-block;
          width: 6.5vw;
          font-size: 19px;
          font-size: 0.93vw;
          font-weight: 500;
          color: #2d2d2d;
        }
        textarea {
          width: 12vw !important;
          height: 4vw !important;
          font-size: 0.88vw;
          // margin-left: 0.5vw;
          padding: 0.5vw;
          border-radius: 7px;
          border: 1.7px solid #2d2d2d;
          resize: none;
          outline: none;
          color: #2d2d2d;
          overflow: hidden;
        }
      }
    }
    .main23 {
      .main23Item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 1.5vh;
        padding-left: 4vw;
        span {
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          display: inline-block;
          width: 4.5vw;
          font-size: 0.93vw;
          font-weight: 500;
          color: #2d2d2d;
        }
        textarea {
          width: 14vw !important;
          height: 1vw !important;
          font-size: 0.88vw;
          // margin-left: 0.5vw;
          padding: 0.5vw;
          border-radius: 7px;
          border: 1.7px solid #2d2d2d;
          resize: none;
          outline: none;
          color: #2d2d2d;
          overflow: hidden;
        }
      }
      .main23Submit {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-top: 4vh;
        .submit1 {
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-size: 1.03vw;
          font-weight: 500;
          color: #2d2d2d;
          border: 0.1vw solid #2d2d2d;
          border-radius: 22px;
          background-color: #eae8e4;
          padding: 0.1vw 0.6vw;
          transition: all 0.3s;
          transition: all 0.5s;
          margin-right: 2.3vw;
          cursor: pointer;
        }
        .submit1:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
</style>
